import { Link } from "react-router-dom";
import './logo.css';
import CM from "../../../../assets/images/logo/logo.png";

export default function MenuLogo(props) {
    const backendUrl = process.env.NODE_ENV === 'local' 
        ? 'http://localhost:5000' 
        : 'https://compliance-backend.esgfit.in';
    
    const logoUrl = props.logo ? `${backendUrl}${props.logo}` : CM;

    return (
        <Link
            to="/"
            className="da-d-flex logo-container da-align-items-end"
            onClick={props.onClose}
            style={{ justifyContent: 'center' }}
        >
            <img className="da-logo" src={logoUrl} alt="ESGFIT-logo" />
        </Link>
    );
}